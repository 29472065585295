<template>
    <ImagePopup v-if="bannerPopupVisible" :image-url="getRsvpGuestEvent?.ClubImage" :on-close-popup="showBannerPopup"/>
    <div key="SignInPageDesktopView" class="xsm:hidden lg:grid grid-cols-2">
        <div class="bg-brand-color relative">
            <div v-if="getRsvpGuestEvent.id">
                <img alt="" class="object-cover h-screen w-full object-top" :src="getRsvpGuestEvent.ClubImage"/>
                <div @click="showBannerPopup(true)" class="bottom-5 right-5 w-10 h-10 bg-brand-color-400 rounded-full absolute flex flex-col justify-center items-center cursor-pointer">
                    <Icon name="material-symbols:fullscreen-rounded" class="text-white w-6 h-6 mx-auto hover:w-7 hover:h-7 transition duration-100"/>
                </div>
            </div>
<!--            <CompanyImage v-else :company-image="getRsvpGuestCompany.banner" class="h-screen w-full"/>-->
        </div>
        <div class="flex max-h-screen min-h-screen overflow-y-scroll bg-brand-color flex-1 flex-col py-12 sm:px-6 lg:px-8 xsm:pt-5 md:py-16">
            <div class="sm:mx-auto sm:w-full sm:max-w-md">
                <img v-if="getRsvpGuestCompany.logo" :src="getRsvpGuestCompany.logo" alt="" class="mx-auto max-w-24 max-h-24"/>
                <div class="flex flex-col mt-4">
                    <span class="text-center text-xl font-medium text-white">{{ getRsvpGuestEvent.ClubName }}</span>
                </div>
            </div>
            <!--Slot-->
            <div >
                <slot/>
            </div>

            <div class="flex flex-row mt-5 text-white justify-center items-center">
                <span>{{('Powered by')}}</span>
                <img class="h-5 w-auto ml-3 mr-1" src="~/assets/icon/app.png" alt=""/>
                <span>Aphelia.io</span>
            </div>
<!--            <footer class="w-1/2 bg-white text-gray-900 px-7 shadow-inner fixed right-0  bottom-0  left-50 z-[100]">-->
<!--                <div class="flex flex-row justify-between  py-3">-->
<!--                    <div class="flex flex-row  items-center justify-center  text-xs space-x-2">-->
<!--                        <div class="mb-[18px]">-->
<!--                            <img class="h-8 w-auto ml-3 mr-1" src="~/assets/icon/logo-dark.svg" alt=""/>-->
<!--                        </div>-->
<!--                        <span class="text-gray-400 text-sm" >All rights reserved.</span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </footer>-->
        </div>
    </div>
<!--    <div class="flex flex-col lg:hidden">
        <div class="z-0">
            <div v-if="getRsvpGuestEvent.id">
                <img alt="" class="object-cover h-[500px] w-screen object-top" :src="getRsvpGuestEvent.ClubImage"/>
            </div>
&lt;!&ndash;            <CompanyImage v-else :company-image="getRsvpGuestCompany.banner" class="h-[500px] w-screen"/>&ndash;&gt;
        </div>
        <div class="w-full z-10 -mt-44 bg-gradient-to-t from-brand-color from-70%">
            <div class="flex min-h-full flex-col xsm:col-span-5 xsm:px-4 sm:col-span-5 md:col-span-3 justify-center">
                <div class="xsm:w-full sm:w-full md:w-4/5 mt-5 mb-20 md:mx-auto">
                    <div class="col-span-3 flex min-h-full flex-1 flex-col justify-center py-12 xsm:px-0 lg:px-8 xsm:pt-5 md:py-16">
                        <div class="mt-5 xsm:mx-auto sm:w-full sm:max-w-[480px]">
                            &lt;!&ndash;Slot&ndash;&gt;
                            <div>
                                <slot/>
                            </div>
                            <div class="flex flex-row mt-5 text-white justify-center items-center text-xs">
                                <p>{{('Powered by')}}</p>
                                <img class="h-5 w-auto mx-2" src="~/assets/icon/app.png" alt=""/>
                                <p>Aphelia.io</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
    <div class="flex flex-col lg:hidden">
        <div class="absolute top-2 right-3 z-10">
        </div>
        <div class="z-0">
            <div v-if="getRsvpGuestEvent?.ClubImage">
                <img alt="" :class="clickedViewFlyer?'h-full':'h-[500px]'" class="object-cover w-screen object-top" :src="getRsvpGuestEvent?.ClubImage"/>
            </div>
            <div v-else class="h-[500px] bg-brand-color"></div>
        </div>
        <div :class="clickedViewFlyer?'mt-0 to-brand-color-400':'-mt-44'" class="bg-gradient-to-t from-brand-color from-50% w-full z-10 transition-all	ease-in delay-400">
            <div class="flex min-h-full flex-col xsm:col-span-5 xsm:px-4 sm:col-span-5 md:col-span-3 justify-center">
                <div class="xsm:w-full sm:w-full md:w-4/5 mt-5 md:mx-auto">
                    <div class="col-span-3 flex min-h-full flex-1 flex-col justify-center pt-12  lg:px-8 xsm:pt-5 md:py-16">
                        <div class="mt-5 w-full ">
                            <div v-if="!clickedViewFlyer" class="flex flex-row mb-10 text-white justify-center items-center">
                                <button @click="viewFullFlyer" class="  ">
                                    <p class="text-white font-poppins text-xs bg-brand-color-700 px-2 py-1 rounded-md">{{('VIEW FULL FLYER')}}</p>
                                    <ChevronDownIcon  class="mx-auto h-5 w-5 text-white" aria-hidden="true" />
                                </button>
                            </div>
                            <!--Slot-->
                            <div>
                                <slot/>
                            </div>
                            <div class="flex flex-row mt-5 justify-center items-center text-xs text-white">
                                <p class="text-gray-300">{{('Powered by')}}</p>
                                <img class="h-5 w-auto ml-3 mr-1" src="~/assets/icon/app.png" alt=""/>
                                <p>Aphelia.io</p>
                            </div>
                            <div class="h-10"></div>
                            <HeightHundred/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!--        <footer class="bg-footer-bg-color text-gray-900 shadow-inner ">-->
<!--            <div class="flex flex-row justify-between items-center  py-3">-->
<!--                <div class="flex flex-row items-center justify-center text-xs space-x-2">-->
<!--                    <div class="mb-[17px]">-->
<!--                        <img class="h-7 w-auto ml-3 mr-1" src="~/assets/icon/logo-dark.svg" alt=""/>-->
<!--                    </div>-->
<!--                    <span class="text-gray-400">All rights reserved.</span>-->
<!--                </div>-->
<!--            </div>-->
<!--        </footer>-->
    </div>
    <Notification/>
</template>

<script setup lang="ts">
import CompanyImage from "~/components/Image/CompanyImage.vue";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import {ChevronDownIcon} from "@heroicons/vue/20/solid";
import {companyStore} from "~/store/companyStore";
import {storeToRefs} from "pinia";
import {clubStore} from "~/store/clubStore";
import ImagePopup from "~/components/Image/ImagePopup.vue";


const useCompanyStore = companyStore();
const useClubStore = clubStore();
const {getRsvpGuestCompany} = storeToRefs(useCompanyStore)
const {getRsvpGuestEvent} = storeToRefs(useClubStore)

const bannerPopupVisible = ref<boolean>(false);
let clickedViewFlyer = ref<boolean>(false);

const viewFullFlyer =  ()=> {
    clickedViewFlyer.value = true;
}

const showBannerPopup = (value:boolean) => {
    bannerPopupVisible.value = value;
}

</script>